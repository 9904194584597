<script setup>

</script>

<template>
  <svg class="details-icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          d="M12 21.6a9.6 9.6 0 1 0 0-19.2 9.6 9.6 0 0 0 0 19.2Zm4.448-10.448-3.6-3.6a1.2 1.2 0 0 0-1.696 1.696l1.551 1.552H8.4a1.2 1.2 0 1 0 0 2.4h4.303l-1.551 1.552a1.2 1.2 0 1 0 1.696 1.696l3.6-3.6a1.2 1.2 0 0 0 0-1.696Z"
          clip-rule="evenodd"></path>
  </svg>
</template>

<style scoped>
.details-icon {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
}
</style>