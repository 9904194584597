<script>
export default {
  name: 'InstagramIcon',
  props: {
    employeeName: {
      type: String,
      required: true,
    },
  },
  methods: {
    captureCustomEvent() {
      this.$posthog.capture('instagram_icon_clicked', {
        'employee': this.employeeName
      })
    }
  }
}
</script>

<template>
  <svg @click="captureCustomEvent" width="28" height="28" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
    <path d="M17.5 6.5h.01"></path>
  </svg>
</template>

<style scoped>


</style>