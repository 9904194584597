<script>
export default {
  name: 'FacebookIcon',
  props: {
    employeeName: {
      type: String,
      required: true,
    },
  },
  methods: {
    captureCustomEvent() {
      this.$posthog.capture('facebook_icon_clicked', {
        'employee': this.employeeName
      })
    }
  }
}
</script>

<template>
  <svg @click="captureCustomEvent" width="28" height="28" fill="none" stroke="currentColor" stroke-linecap="round"
       stroke-linejoin="round" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 10v4h3v7h4v-7h3l1-4h-4V8a1 1 0 0 1 1-1h3V3h-3a5 5 0 0 0-5 5v2H7Z"></path>
  </svg>
</template>

<style scoped>

</style>