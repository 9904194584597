import posthog from "posthog-js";

export default {
    install(app) {
        const userConsent = localStorage.getItem('posthog_user_consent');

        if (userConsent === 'accepted') {
            // User has accepted tracking
            app.config.globalProperties.$posthog = posthog.init(
                "phc_jyD1hmmEMpg4tv6tQus7iULHG9bsqgpCNR1KcllWKfc",
                {
                    api_host: "https://us.i.posthog.com",
                    autocapture: true, // Enable autocapture if user consents
                    capture_pageview: true // Enable page view capture if user consents
                }
            );
        } else {
            // User has not accepted tracking or has opted out
            app.config.globalProperties.$posthog = posthog.init(
                "phc_jyD1hmmEMpg4tv6tQus7iULHG9bsqgpCNR1KcllWKfc",
                {
                    api_host: "https://us.i.posthog.com",
                    autocapture: false, // Disable autocapture
                    capture_pageview: false // Disable page view capture
                }
            );
            // Ensure PostHog is not capturing any data
            app.config.globalProperties.$posthog.opt_out_capturing();
        }
    },
};